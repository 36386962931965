// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import './modules/custom-event-polyfill'
import './modules/object-foreach-polyfill'
import './modules/object-assign'
import 'console-polyfill'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

import { loadComponents, removeComponents } from './core/componentUtils'
import Swup from 'swup'
import swupGtmPlugin from 'swup/plugins/swupGtmPlugin'
import detectie from 'detectie'

loadComponents()

/**
 * Swup stuff
 */

if (!detectie()) {
    const swup = new Swup({
        LINK_SELECTOR:
            'a[href^="' +
            window.location.origin +
            '"]:not([data-no-swup]):not([data-component]), a[href^="/"]:not([data-no-swup]):not([data-component]), a[href^="#"]:not([data-no-swup]):not([data-component])',
        elements: ['#swup', '#nav', '#lang-nav', '#btn-contact', '#footer-copy', '#footer-holder'],
        debugMode: false,
        //doScrollingRightAway: true,
        animateScroll: true,
        preload: false,
        cache: true,
        plugins: [swupGtmPlugin],
    })

    // components
    swup.on('willReplaceContent', (event) => {
        document.querySelectorAll('[data-swup]').forEach((element) => {
            removeComponents(element)
        })
    })

    swup.on('contentReplaced', (event) => {
        document.querySelectorAll('[data-swup]').forEach((element) => {
            loadComponents(element)
        })
    })

    // scroll
    swup.on('willReplaceContent', (event) => {
        swup.options.animateScroll = false
    })

    swup.on('contentReplaced', (event) => {
        Nette.reinit()
        setTimeout(() => {
            swup.options.animateScroll = true
        })
    })
} else {
    // fix for IE not scrolling to anchor elements in overflow: hidden elements
    queryAll('[href^="#"]').forEach((link) => {
        link.addEventListener('click', (event) => {
            document.documentElement.scrollTop =
                Math.round(document.querySelector(event.target.hash).getBoundingClientRect().top) +
                window.pageYOffset
        })
    })
}

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'

import { queryAll } from './utils/dom'
